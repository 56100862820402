<template>
  <div class="kt-widget5">
    <perfect-scrollbar style="max-height: 100vh; position: relative;">
      <template v-for="(item, i) in notices">
        <div class="kt-widget5__item" :key="`item-${i}`">
          <div class="kt-widget5__section">
            <span class="kt-widget5__info">
              <i :class="'fa fa-genderless ' + fontColor()"></i>
            </span>
            <span href="#" class="kt-widget5__title kt-font-bolder">
              {{ item.title }}
            </span>
            <p class="kt-widget5__desc">
              {{ item.text }}
            </p>
            <div class="kt-widget5__info">
              <span>{{ $t("GENERAL.NOTICE_FROM") }}:</span>
              <span class="kt-font-info">
                {{
                  item.by === "UNIVERSITY_ADMINISTRATION"
                    ? $t("GENERAL.UNIVERSITY_ADMINISTRATION")
                    : item.by
                }}
              </span>
              <br />
              <span>{{ $t("GENERAL.NOTICE_END_DATE") }}:</span>
              <span class="kt-font-info">{{ item.time }}</span>
            </div>
          </div>
        </div>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      font_colors: [
        "kt-font-danger",
        "kt-font-success",
        "kt-font-brand",
        "kt-font-warning",
        "kt-font-info"
      ],
      notices: []
    };
  },
  mounted() {
    ApiService.get("notices").then(({ data }) => {
      this.notices = data;
    });
  },
  methods: {
    fontColor() {
      return this.font_colors[
        Math.floor(Math.random() * this.font_colors.length)
      ];
    }
  }
};
</script>
