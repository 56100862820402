var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"kt-widget2"},[_c('perfect-scrollbar',{staticStyle:{"max-height":"100vh","position":"relative"}},[_vm._l((_vm.assignment_notices),function(assignment_notice,i){return [_c('router-link',{key:("item-" + i),class:'kt-widget2__item ' + _vm.noticesColor(),attrs:{"to":{
          name: 'subjectAssignmentList',
          params: {
            class_id: assignment_notice.class_id,
            subject_id: assignment_notice.subject_id
          }
        }}},[_c('div',{staticClass:"kt-widget2__info kt-padding-l-20"},[_c('span',{staticClass:"kt-widget2__title kt-font-bolder"},[_vm._v(" "+_vm._s(_vm.$t("UPLOADED_ASSIGNMENT.ASSIGNMENT_TITLE"))+": "+_vm._s(assignment_notice.title)+" ")]),_c('span',{staticClass:"kt-widget2__username"},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.SUBJECT"))+": "+_vm._s(assignment_notice.subject)+" "+_vm._s(assignment_notice.text)+" ")])]),_vm._v(" "),_c('span',{staticClass:"kt-widget2__username"},[_c('b-badge',{staticClass:"mr-1 font-weight-bold",attrs:{"variant":_vm.badgeColor()}},[_vm._v(" "+_vm._s(_vm.$t("ASSIGNMENT.DUE_DATE"))+" : "+_vm._s(assignment_notice.due_date)+" ")])],1)])]}),_vm._l((_vm.quiz_notices),function(quiz_notice,k){return [_c('router-link',{key:("item-" + k),class:'kt-widget2__item ' + _vm.noticesColor(),attrs:{"to":{
          name: 'subjectQuizList',
          params: {
            class_id: quiz_notice.class_id,
            subject_id: quiz_notice.subject_id
          }
        }}},[_c('div',{staticClass:"kt-widget2__info kt-padding-l-20"},[_c('span',{staticClass:"kt-widget2__title kt-font-bolder"},[_vm._v(" "+_vm._s(_vm.$t("UPLOADED_QUIZ.QUIZ_TITLE"))+": "+_vm._s(quiz_notice.title)+" ")]),_c('span',{staticClass:"kt-widget2__username"},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.SUBJECT"))+": "+_vm._s(quiz_notice.subject)+" "+_vm._s(quiz_notice.text)+" ")])]),_vm._v(" "),_c('span',{staticClass:"kt-widget2__username mt-5"},[_c('b-badge',{staticClass:"ml-5 font-weight-bold",attrs:{"variant":"info"}},[_vm._v(" "+_vm._s(_vm.$t("QUIZ.DATE"))+" : "+_vm._s(quiz_notice.date)+" ")]),_c('b-badge',{staticClass:"ml-5 font-weight-bold",attrs:{"variant":"success"}},[_vm._v(" "+_vm._s(_vm.$t("QUIZ.START_TIME"))+" : "+_vm._s(quiz_notice.start_time)+" ")]),_c('b-badge',{staticClass:"ml-5 font-weight-bold",attrs:{"variant":"danger"}},[_vm._v(" "+_vm._s(_vm.$t("QUIZ.END_TIME"))+" : "+_vm._s(quiz_notice.end_time)+" ")])],1)])]})],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }