<template>
  <div class="kt-widget2">
    <perfect-scrollbar style="max-height: 100vh; position: relative;">
      <template v-for="(assignment_notice, i) in assignment_notices">
        <router-link
          :class="'kt-widget2__item ' + noticesColor()"
          :key="`item-${i}`"
          :to="{
            name: 'subjectAssignmentList',
            params: {
              class_id: assignment_notice.class_id,
              subject_id: assignment_notice.subject_id
            }
          }"
        >
          <div class="kt-widget2__info kt-padding-l-20">
            <span class="kt-widget2__title kt-font-bolder">
              {{ $t("UPLOADED_ASSIGNMENT.ASSIGNMENT_TITLE") }}: &nbsp;
              {{ assignment_notice.title }}
            </span>
            <span class="kt-widget2__username">
              {{ $t("GENERAL.SUBJECT") }}: &nbsp;
              {{ assignment_notice.subject }} &nbsp;
              {{ assignment_notice.text }}
            </span>
          </div>
          &nbsp;&nbsp;
          <span class="kt-widget2__username">
            <b-badge class="mr-1 font-weight-bold" :variant="badgeColor()">
              {{ $t("ASSIGNMENT.DUE_DATE") }} : &nbsp;
              {{ assignment_notice.due_date }}
            </b-badge>
          </span>
        </router-link>
      </template>
      <template v-for="(quiz_notice, k) in quiz_notices">
        <router-link
          :class="'kt-widget2__item ' + noticesColor()"
          :key="`item-${k}`"
          :to="{
            name: 'subjectQuizList',
            params: {
              class_id: quiz_notice.class_id,
              subject_id: quiz_notice.subject_id
            }
          }"
        >
          <div class="kt-widget2__info kt-padding-l-20">
            <span class="kt-widget2__title kt-font-bolder">
              {{ $t("UPLOADED_QUIZ.QUIZ_TITLE") }}: &nbsp;
              {{ quiz_notice.title }}
            </span>
            <span class="kt-widget2__username">
              {{ $t("GENERAL.SUBJECT") }}: &nbsp;
              {{ quiz_notice.subject }} &nbsp;
              {{ quiz_notice.text }}
            </span>
          </div>
          &nbsp;&nbsp;
          <span class="kt-widget2__username mt-5">
            <b-badge class="ml-5 font-weight-bold" variant="info">
              {{ $t("QUIZ.DATE") }} : &nbsp;
              {{ quiz_notice.date }}
            </b-badge>
            <b-badge class="ml-5 font-weight-bold" variant="success">
              {{ $t("QUIZ.START_TIME") }} : &nbsp;
              {{ quiz_notice.start_time }}
            </b-badge>
            <b-badge class="ml-5 font-weight-bold" variant="danger">
              {{ $t("QUIZ.END_TIME") }} : &nbsp;
              {{ quiz_notice.end_time }}
            </b-badge>
          </span>
        </router-link>
      </template>
    </perfect-scrollbar>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
export default {
  name: "AssignmentAndQuizNotices",
  data() {
    return {
      notices_colors: [
        "kt-widget2__item--danger",
        "kt-widget2__item--success",
        "kt-widget2__item--brand",
        "kt-widget2__item--warning",
        "kt-widget2__item--info"
      ],
      badge_colors: ["danger", "success", "brand", "warning", "info"],
      assignment_notices: [],
      quiz_notices: []
    };
  },
  methods: {
    noticesColor() {
      return this.notices_colors[
        Math.floor(Math.random() * this.notices_colors.length)
      ];
    },
    badgeColor() {
      return this.badge_colors[
        Math.floor(Math.random() * this.badge_colors.length)
      ];
    }
  },
  mounted() {
    ApiService.get("student/assignment-notices").then(({ data }) => {
      this.assignment_notices = data;
    });
    ApiService.get("student/quiz-notices").then(({ data }) => {
      this.quiz_notices = data;
    });
  }
};
</script>
