var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('KTPortlet',{attrs:{"title":_vm.$t('SCHEDULE.TITLE')},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('b-table',{attrs:{"fields":_vm.schedule_fields,"items":_vm.schedules,"busy":_vm.schedule_busy,"striped":"","bordered":"","head-variant":"dark","show-empty":""},scopedSlots:_vm._u([{key:"empty",fn:function(scope){return [_c('p',{staticClass:"text-center"},[_vm._v(" "+_vm._s(_vm.$t("SCHEDULE.NO_RESULT"))+" ")])]}},{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-danger my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v(_vm._s(_vm.$t("GENERAL.PLEASE_WAIT")))])],1)]},proxy:true},{key:"cell(online_class)",fn:function(ref){
var item = ref.item;
return [_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"pill":"","size":"sm","variant":"success","title":_vm.$t('GENERAL.JOIN')},on:{"click":function($event){return _vm.openConferencePage(item)}}},[_vm._v(" "+_vm._s(_vm.$t("GENERAL.JOIN"))+" ")])]}}])})]},proxy:true}])}),_c('div',{staticClass:"row kt-margin-b-20"}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-xl-4 order-lg-1 order-xl-1"},[_c('KTPortlet',_vm._b({scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('CounterWidget')]},proxy:true}])},'KTPortlet',{
          title: '(~!~)',
          class: 'kt-portlet--fit kt-portlet--skin-solid',
          headClass: 'kt-portlet__head--noborder kt-portlet__space-x',
          headSize: 'lg',
          fluidHeight: true,
          headOverlay: true,
          headNoBorder: true
        },false))],1),_c('div',{staticClass:"col-lg-6 col-xl-8 order-lg-1 order-xl-1"},[_c('KTPortlet',{attrs:{"title":_vm.$t('DASHBOARD.LATEST_NOTICES')},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('Notices')]},proxy:true}])})],1)]),_c('div',{staticClass:"row kt-margin-b-20"}),(_vm.userType() === 'student')?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6 col-xl-4 order-lg-1 order-xl-1"},[_c('KTPortlet',{attrs:{"title":_vm.$t('DASHBOARD.ASSIGNMENT_QUIZ_NOTICES')},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('AssignmentAndQuizNotices')]},proxy:true}],null,false,63703806)})],1)]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }