<template>
  <div>
    <KTPortlet :title="$t('SCHEDULE.TITLE')">
      <template v-slot:body>
        <b-table
          :fields="schedule_fields"
          :items="schedules"
          :busy="schedule_busy"
          striped
          bordered
          head-variant="dark"
          show-empty
        >
          <template v-slot:empty="scope">
            <p class="text-center">
              {{ $t("SCHEDULE.NO_RESULT") }}
            </p>
          </template>
          <template v-slot:table-busy>
            <div class="text-center text-danger my-2">
              <b-spinner class="align-middle"></b-spinner>
              <strong>{{ $t("GENERAL.PLEASE_WAIT") }}</strong>
            </div>
          </template>
          <template v-slot:cell(online_class)="{ item }">
            <b-button
              pill
              size="sm"
              variant="success"
              v-b-tooltip.hover
              :title="$t('GENERAL.JOIN')"
              @click="openConferencePage(item)"
            >
              {{ $t("GENERAL.JOIN") }}
            </b-button>
          </template>
        </b-table>
      </template>
    </KTPortlet>
    <div class="row kt-margin-b-20"></div>

    <div class="row">
      <div class="col-lg-6 col-xl-4 order-lg-1 order-xl-1">
        <KTPortlet
          v-bind="{
            title: '(~!~)',
            class: 'kt-portlet--fit kt-portlet--skin-solid',
            headClass: 'kt-portlet__head--noborder kt-portlet__space-x',
            headSize: 'lg',
            fluidHeight: true,
            headOverlay: true,
            headNoBorder: true
          }"
        >
          <template v-slot:body>
            <CounterWidget></CounterWidget>
          </template>
        </KTPortlet>
      </div>

      <div class="col-lg-6 col-xl-8 order-lg-1 order-xl-1">
        <KTPortlet v-bind:title="$t('DASHBOARD.LATEST_NOTICES')">
          <template v-slot:body>
            <Notices></Notices>
          </template>
        </KTPortlet>
      </div>
    </div>

    <div class="row kt-margin-b-20"></div>

    <div class="row" v-if="userType() === 'student'">
      <div class="col-lg-6 col-xl-4 order-lg-1 order-xl-1">
        <KTPortlet v-bind:title="$t('DASHBOARD.ASSIGNMENT_QUIZ_NOTICES')">
          <template v-slot:body>
            <AssignmentAndQuizNotices></AssignmentAndQuizNotices>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/common/api.service";
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import CounterWidget from "@/views/partials/widgets/CounterWidget.vue";
import Notices from "@/views/partials/widgets/Notices.vue";
import AssignmentAndQuizNotices from "@/views/partials/widgets/AssignmentAndQuizNotices";
import { getUserType } from "@/common/jwt.service";

export default {
  name: "dashboard",
  components: {
    KTPortlet,
    CounterWidget,
    Notices,
    AssignmentAndQuizNotices
  },
  data() {
    return {
      schedules: [],
      schedule_busy: true,
      schedule_fields: [
        { subject: this.$t("GENERAL.SUBJECT") },
        {
          lecturer:
            this.userType() === "student"
              ? this.$t("GENERAL.LECTURER")
              : this.$t("GENERAL.CLASS")
        },
        { week_days: this.$t("SCHEDULE.WEEK_DAYS") },
        { start_time: this.$t("SCHEDULE.START_TIME") },
        { end_time: this.$t("SCHEDULE.END_TIME") },
        { online_class: this.$t("GENERAL.ONLINE_CLASS") }
      ]
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: this.$t("MENU.DASHBOARD") }
    ]);
    let thisVar = this;
    if (this.userType() === "student") {
      ApiService.get("student/student-today-schedule-list").then(({ data }) => {
        this.schedules = data.map(item => {
          let days = "",
            week_day = item["week_days"].split(",");
          week_day.forEach(function(item) {
            days += thisVar.$t("SCHEDULE.WEEK_DAY." + item) + ", ";
          });
          return {
            subject_id: item.subject_id,
            student_class_id: item.student_class_id,
            subject: item.subject,
            lecturer: item.lecturer,
            start_time: item.start_time,
            end_time: item.end_time,
            week_days: days.substring(0, days.length - 2)
          };
        });
        this.schedule_busy = false;
      });
    } else if (this.userType() === "lecturer") {
      ApiService.get("lecturer/lecturer-schedule-list").then(({ data }) => {
        this.schedules = data.map(item => {
          let days = "",
            week_day = item["week_days"].split(",");
          week_day.forEach(function(item) {
            days += thisVar.$t("SCHEDULE.WEEK_DAY." + item) + ", ";
          });
          return {
            subject_id: item.subject_id,
            student_class_id: item.student_class_id,
            subject: item.subject,
            lecturer: item.class,
            start_time: item.start_time,
            end_time: item.end_time,
            week_days: days.substring(0, days.length - 2)
          };
        });
        this.schedule_busy = false;
      });
    }
  },
  methods: {
    userType() {
      return getUserType();
    },
    openConferencePage(schedule) {
      this.$router.push({
        name: "onlineClass",
        params: {
          student_class_id: schedule.student_class_id,
          subject_id: schedule.subject_id
        }
      });
    }
  }
};
</script>
