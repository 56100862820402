<template>
  <div class="kt-widget17" v-if="userType() === 'student'">
    <div
      class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides"
      style="background-color: #fd397a"
    >
      <div class="kt-widget17__chart"></div>
    </div>
    <div class="kt-widget17__stats">
      <div class="kt-widget17__items">
        <div class="kt-widget17__item">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.934 16.072l6.426-5.116.158-.125a1.5 1.5 0 00.003-2.35l-6.587-5.238a1.5 1.5 0 00-1.868 0L4.475 8.485a1.5 1.5 0 00-.001 2.347l.156.124 6.436 5.117a1.5 1.5 0 001.868 0z" fill="#000" fill-rule="nonzero"/><path d="M11.056 18.67l-5.72-4.547a.903.903 0 00-1.276.154.91.91 0 00.166 1.279l6.861 5.247a1.5 1.5 0 001.823 0l6.858-5.243a.915.915 0 00.167-1.288.91.91 0 00-1.286-.155l-5.725 4.554a1.5 1.5 0 01-1.868 0z" fill="#000" opacity=".3"/></g></svg></span>
          <span class="kt-widget17__subtitle">
            {{ $t("DASHBOARD.NUMBER_OF_SUBJECTS") }}
          </span>
          <span class="kt-widget17__desc">{{
            student.number_of_subjects
          }}</span>
        </div>
        <div class="kt-widget17__item">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--brand'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5 3h1a1 1 0 011 1v16a1 1 0 01-1 1H5a1 1 0 01-1-1V4a1 1 0 011-1zm5 0h1a1 1 0 011 1v16a1 1 0 01-1 1h-1a1 1 0 01-1-1V4a1 1 0 011-1z" fill="#000"/><rect fill="#000" opacity=".3" transform="rotate(-19 17.826 11.946)" x="16.326" y="2.946" width="3" height="18" rx="1"/></g></svg>
          </span>
          <span class="kt-widget17__subtitle">
            {{ $t("DASHBOARD.CLASS_MATERIALS_UPLOADED") }}
          </span>
          <span class="kt-widget17__desc">{{
            student.class_materials_uploaded
          }}</span>
        </div>
      </div>
      <div class="kt-widget17__items">
        <div class="kt-widget17__item">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--danger'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M3 16h2a1 1 0 000-2H3v-2h2a1 1 0 000-2H3V8h2a1 1 0 100-2H3V4a1 1 0 011-1h6a1 1 0 011 1v15a1 1 0 01-1 1H4a1 1 0 01-1-1v-3z" fill="#000" opacity=".3"/><path d="M16 3h3a2 2 0 012 2v10.249a2 2 0 01-.495 1.317l-2.629 3.004a.5.5 0 01-.752 0l-2.63-3.004A2 2 0 0114 15.249V5a2 2 0 012-2z" fill="#000"/></g></svg></span>
          <span class="kt-widget17__subtitle">
            {{ $t("DASHBOARD.RESULTS_ANNOUNCED") }}
          </span>
          <span class="kt-widget17__desc">{{ student.results_announced }}</span>
        </div>
        <div class="kt-widget17__item">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--warning'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.704 14l2.963-4h-2.223V6L9 12h2.222v2H6a1 1 0 01-1-1V3a1 1 0 011-1h12a1 1 0 011 1v10a1 1 0 01-1 1h-5.296z" fill="#000" opacity=".3"/><path d="M9.804 10.914L9 12h2.222v4l4.445-6h-.205l4.745-3.427a.5.5 0 01.793.405V17a2 2 0 01-2 2H5a2 2 0 01-2-2V6.978a.5.5 0 01.793-.405l6.011 4.341z" fill="#000"/></g></svg></span>
          <span class="kt-widget17__subtitle">
            {{ $t("DASHBOARD.NEW_MESSAGES") }}
          </span>
          <span class="kt-widget17__desc">{{ student.new_messages }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="kt-widget17" v-else>
    <div
      class="kt-widget17__visual kt-widget17__visual--chart kt-portlet-fit--top kt-portlet-fit--sides"
      style="background-color: #fd397a"
    >
      <div class="kt-widget17__chart"></div>
    </div>
    <div class="kt-widget17__stats">
      <div class="kt-widget17__items">
        <div class="kt-widget17__item">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--success'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M6 9v6a3 3 0 003 3h6v.818C15 20.232 14.232 21 12.818 21H5.182C3.768 21 3 20.232 3 18.818v-7.636C3 9.768 3.768 9 5.182 9H6zm11 7v-6a3 3 0 00-3-3H8v-.818C8 4.768 8.768 4 10.182 4h7.636C19.232 4 20 4.768 20 6.182v7.636C20 15.232 19.232 16 17.818 16H17z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M9.273 9h4.454C14.552 9 15 9.448 15 10.273v4.454c0 .825-.448 1.273-1.273 1.273H9.273C8.448 16 8 15.552 8 14.727v-4.454C8 9.448 8.448 9 9.273 9z" fill="#000"/></g></svg></span>
          <span class="kt-widget17__subtitle">
            {{ $t("MENU.ASSIGNED_CLASSES") }}
          </span>
          <span class="kt-widget17__desc">{{ lecturer.assigned_classes }}</span>
        </div>
      </div>
      <div class="kt-widget17__items">
        <div class="kt-widget17__item">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--brand'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M5 3h1a1 1 0 011 1v16a1 1 0 01-1 1H5a1 1 0 01-1-1V4a1 1 0 011-1zm5 0h1a1 1 0 011 1v16a1 1 0 01-1 1h-1a1 1 0 01-1-1V4a1 1 0 011-1z" fill="#000"/><rect fill="#000" opacity=".3" transform="rotate(-19 17.826 11.946)" x="16.326" y="2.946" width="3" height="18" rx="1"/></g></svg>
          </span>
          <span class="kt-widget17__subtitle">
            {{ $t("DASHBOARD.CLASS_MATERIALS_UPLOADED") }}
          </span>
          <span class="kt-widget17__desc">{{
            lecturer.materials_uploaded
          }}</span>
        </div>
        <div class="kt-widget17__item">
          <span class="kt-widget17__icon">
            <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'kt-svg-icon kt-svg-icon--warning'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M12.704 14l2.963-4h-2.223V6L9 12h2.222v2H6a1 1 0 01-1-1V3a1 1 0 011-1h12a1 1 0 011 1v10a1 1 0 01-1 1h-5.296z" fill="#000" opacity=".3"/><path d="M9.804 10.914L9 12h2.222v4l4.445-6h-.205l4.745-3.427a.5.5 0 01.793.405V17a2 2 0 01-2 2H5a2 2 0 01-2-2V6.978a.5.5 0 01.793-.405l6.011 4.341z" fill="#000"/></g></svg></span>
          <span class="kt-widget17__subtitle">
            {{ $t("DASHBOARD.NEW_MESSAGES") }}
          </span>
          <span class="kt-widget17__desc">{{ lecturer.new_messages }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getUserType } from "@/common/jwt.service";
import ApiService from "@/common/api.service";

export default {
  data() {
    return {
      student: {
        number_of_subjects: "-",
        class_materials_uploaded: "-",
        results_announced: "-",
        new_messages: "-"
      },
      lecturer: {
        assigned_classes: "-",
        materials_uploaded: "-",
        new_messages: "-"
      }
    };
  },
  mounted() {
    ApiService.get(this.userType() + "/get-dashboard-statistics").then(
      ({ data }) => {
        this[this.userType()] = data;
      }
    );
  },
  computed: {
    ...mapGetters(["layoutConfig"])
  },
  methods: {
    userType() {
      return getUserType();
    }
  }
};
</script>
